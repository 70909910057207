@font-face {
    font-family: 'Gill Sans';
    src: url('fonts/GillSansMTPro-Book.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Gill Sans Light';
    src: url('fonts/GillSansMTPro-Light.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Gill Sans Medium';
    src: url('fonts/GillSansMTPro-Medium.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Gill Sans Bold';
    src: url('fonts/GillSansMTPro-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Satisfy';
    src: url('fonts/Satisfy-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: Poppins;
    src: url('fonts/Poppins-Regular.otf');
    font-display: swap;
}
@font-face {
    font-family: Montserrat;
    src: url('fonts/Montserrat-Medium.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'League Spartan';
    src: url('fonts/LeagueSpartan-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Libre Baskerville';
    src: url('fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-display: swap;
}
body {
    font-family: 'Gill Sans' !important;
}
h1, h2, h3, h4, a, p {
    font-family: 'Gill Sans' !important;
}
h1 {
    font-size: 30px;
    line-height: 42px;
    color: #000;
}
h2 {
    font-size: 30px;
    line-height: 42px;
    margin-top:0;
}
h3 {
    font-size:24px;
    line-height:18px;
    font-weight:600;
}
h4 {
    font-size: 20px;
    font-weight: 100;
    color: #000;
    text-transform: none;
}
h4 strong {
    font-family: 'Gill Sans Bold';
    font-weight: 300;
}

.grey-text{
    color:#707070;
}
p, body {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0;
}
a, .pf-highlight-nav {
    color: #000;
}
a:hover, .dropdownlink.w-dropdown-link:hover .pf-highlight-nav, .mbss-text .mbss-content p a:hover, .mbss-text div.mbss-content p a:hover {
    color: #f5dae9 !important;
}
input, .input , .w-input {
    height: 45px;
    border-color: #707070;
}
.ecs-main-container-nbp {
    padding-top:258px;
}
.the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
    padding-top: 290px;
}
.ecs-main-container {
    padding-top: 280px;
    padding-bottom: 70px;
}
.the-website.pf-promotion-strip-enabled .ecs-main-container {
    padding-top: 300px;
}
.ecs-row-container {
    float: none;
    margin: auto;
    max-width: 1500px;
}
.pf-btn.pf-btn-primary, .pf-load-more-btn {
    border: none !important;
    background: #000 !important;
    border-radius: 34px;
    text-transform: uppercase;
    border-radius: 0;
    line-height: 28px;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff !important;
    font-size: 14px;
    height: 40px;
    min-width: 180px;
}
.pf-btn.pf-btn-primary:hover, .pf-load-more-btn:hover {
    background-color: #f5dae9 !important;
    color: #000 !important;
    text-decoration: none !important;
    border-color: #f5dae9 !important;
    cursor: pointer !important;
}
/*Header*/
.topnav {
  height: 40px;
  background-color: #eee9e7;
}
.topnav p{
    margin-top:8px;
    font-size:14px;
}
.nav-menu.top {
    text-align:initial;
}
.ecs-row.mobile-flex-col-logo {
    margin-top:20px;
}
.header.shrink .ecs-row.mobile-flex-col-logo {
    margin-top: 0;
}
#prod-search-btn {
    left: 0;
    background-color: transparent;
}

#prod-search-tbx {
    width: 100%;
    height: 45px;
    border-color: #707070;
    padding-left: 49px;
    font-family: 'Gill Sans Light';
    color: #000;
}
#search-form {
    position:absolute;
    top:20px;
}

#search-form .fa-search {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #000;
    font-size: 18px;
}

.searchform-clear + input[type="search"] {
    padding-right: 44px;
}

.searchform-clear {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 10px 14px;
    font-size: 18px;
    color: #707070;
    cursor: pointer;
}
input[type="search"],
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
#bag-opener {
    font-size: 16px;
    line-height: 24px;
    margin-right: -9px;
    margin-top: 17px;
}
#bag-opener:hover {
    background-color: #fff;
}

#bag-opener > i {
    font-size: 24px;
    line-height: 1;
}

.bag-interaction {
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 201.2% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    display: inline-block;
    text-transform: uppercase;
}
.bag-interaction:hover {
    background-position: left bottom;
    color: #f5dae9;
}
.bag-interaction .pf-hide-mobile {
    display: inline-block;
}
.nav-link.mid {
    border-color: #000;
    color: #000;
}
.flex-header-links {
    display: flex;
    flex-wrap: wrap;
    max-width: 440px;
    margin-top: 34px;
    margin-bottom: 21px;
}
.header-link {
    width: 100px;
    height: 30px;
    background-color:  #eee9e7;
    display: inline-block;
    flex-grow: 1;
    text-align: center;
    text-transform: uppercase;
}
.header-link p {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 12px;
    margin-top: 8px;
}
.header-link:not(:last-child) {
    margin-right: 10px;
}
.header-link:hover {
    background-color: #ededed;
    cursor: pointer;
}
.nav, .nav-menu {
    background-color: #fff;
}
.nav-menu .nav-link {
    color:#000;
}
.nav-menu .nav-link.w-nav-link {
    background-color:  #eee9e7;
    color:#000;
    font-size: 14px;
    line-height: 1;
    padding: 9px 24px 10px;
    letter-spacing: 0;
}
.nav-menu.top .nav-link.w-nav-link{
    padding: 5px 12px 5px;
}
.nav-menu.top .nav-link.w-nav-link span{
    top:-5px;
    left:5px;
    position:relative;
}
.nav-menu.top .ecs-column-2{
    width:28.5%;
    margin-right:2%;
}
.nav-menu.top .ecs-column-2 .text-right{
    text-align:left;
}
#pf-currency-selector {
    padding: 10px 12px 10px;
}
.nav-menu .nav-link:hover,
.nav-menu .nav-link.w--current, .nav-link.nav-dropdown.w-dropdown-toggle.w--open {
    color: #000 !important;
    background-color: #ededed;
}

.nav-link.nav-dropdown.w-dropdown-toggle .dropdown-icon > i {
    font-size: 24px;
}
.mega-list.w-dropdown-list {
    width: 100%;
    border: none;
    padding-top: 10px;
}
.w-dropdown-link.w--current {
    color:#f5dae9!important;
}
.nav-menu.w-nav-menu .mega-list.w-dropdown-list {
    display: block;
    visibility: hidden;
    margin-top: 50px;
    opacity: 0;
    transition: all 600ms ease-in-out !important;
    border: none;
    overflow: auto;
    z-index: 900;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 30vh;
    padding-bottom: 30px;
    left: 0;
    transform: none;
}

.nav-menu.w-nav-menu .mega-list.w-dropdown-list.w--open {
    margin-top: 7px;
    left: 0;
    right: 0;
    transform: none;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
}

.nav-dropdown-strip {
    visibility: hidden;
    opacity: 0;
    transform: scaleX(0);
    position: fixed;
    left: 0;
    right: 0;
    height: 7px;
    background-color: #ededed;
    transition: all 600ms ease-in-out;
    z-index: 900;
}

.nav-dropdown-strip.w--open {
    visibility: visible;
    opacity: 1;
    transform: scaleX(1);
}

.pf-nav-image span {
    color: #000;
    text-align: center;
}
.midnav .nav-menu.w-nav-menu {
    display: flex;
    flex-wrap: wrap;
}
.nav-menu.w-nav-menu > a:first-child, .nav-menu.w-nav-menu > div:first-child {
    margin-left: 0;
}
.nav-menu.w-nav-menu > a:last-child, .nav-menu.w-nav-menu > div:last-child {
    margin-right: 0;
}
.mega-list .heading {
    margin-bottom: 12px;
    font-family: 'Gill Sans Medium' !important;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
}
.shrink .nav-link.nav-dropdown.w--open , .nav-menu .nav-link.nav-dropdown {
    padding: 9px 38px 10px 15px!important;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0;
    position: relative;
}
.nav-container .nav-link .dropdown-icon {
    right: 15px;
    top: 4px;
}
.mega-list .heading {
    margin-bottom: 12px;
    font-family: 'Gill Sans Medium' !important;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    text-decoration: underline;
    color:#000!important;
}
.mega-list .heading:hover {
    color:#f5dae9!important;
}
.pf-highlight-nav-wrapper {
    background-color:  #eee9e7;
}
.midnav {
    padding:0 30px;
}
/*Homepage*/
.slider {
    max-width: 1500px;
    margin: auto;
}
.slider-main-banner-wrapper .slider {
    max-height: 560px;
    height: 37vw;
}
.slider-main-banner-wrapper .w-slider-arrow-left,
.slider-main-banner-wrapper .w-slider-arrow-right,
.slider-bottom-wrapper .w-slider-nav.w-round {
    display: none;
}
.slider-bottom-wrapper i {
    font-size: 1em;
}
.grey-section {
    background-color: #ededed;
}
.pink-section {
    background-color: #f5dae9;
}
.full-width-section {
    position: relative;
    width: 100%;
    display: flex;
}
.full-width-section.pink-section {
    padding: 15px 0;
}
.blogpost-caption h3 {
    line-height:32px;
}
.blog-feed-row .title-wrapper h2 {
    padding-top: 0;
    text-transform:uppercase;
}
.section-under-slider {
    padding-top: 54px;
    padding-bottom: 76px;
}
.section-under-slider .ecs-column-2:first-of-type {
    width:34%;
}
.section-under-slider .ecs-column-2:last-of-type {
    width: 60%;
}
.homepage-row:not(.top-slider-homepage) {
    margin: auto;
    max-width: 1560px;
    padding: 0 30px;
}
.home-images-wrapper {
    padding-top: 43px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1.09fr;
    grid-template-rows: 1fr 1.09fr;
    column-gap: 1.2%;
    row-gap: 2.2%;
}
.home-image-square-1 {
    grid-column: 1;
    grid-row: 1;
}
.home-image-square-2 {
    grid-column: 2;
    grid-row: 1;
}
.home-image-tall {
    grid-column: 3;
    grid-row: 1 / 3;
}
.home-image-wide {
    grid-column: 1 / 3;
    grid-row: 2;
}
.home-second-logo-wrapper {
    padding-top:51px;
    padding-bottom:80px;
}
.home-second-logo-wrapper p{
    color:#707070;
}
.homepage-row.flex-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;
}
.the-website.pf-promotion-strip-enabled .slider-bottom-wrapper .ecs-main-container-nbp, .slider-bottom-wrapper .ecs-main-container-nbp {
    padding-top: 0!important;
}
.slider-bottom-wrapper .w-slider-arrow-left, .slider-bottom-wrapper .w-slider-arrow-right {
    color: rgba(0,0,0,.5);
}
.slider-bottom-wrapper .slider {
    min-height:380px;
    max-height:473px;
    height: 30vw;
}
.top-footer {
    position: relative;
    float: left;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 25px;
    background-color: #f5dae9;
}
.section-under-slider h1 {
    font-size: 48px;
    line-height: 60px;
    margin-top: -20px;
    font-family: Gill Sans Light !important;
    margin-bottom:5px;
}
.section-under-slider h1 strong {
    font-size: 60px;
    line-height: 84px;
    font-family: 'Gill Sans Bold' !important;
    font-weight: 400;
    display:block;
    margin-bottom:5px;
}
.section-under-slider h2 {
    font-size: 36px;
    line-height: 50px;
    font-family: 'Gill Sans Medium' !important;
    font-weight: 400;
}
.section-under-slider h3 {
    font-size: 24px;
    line-height: 34px;
    font-family: 'Gill Sans' !important;
    font-weight: 400;
    margin-bottom:10px;
    margin-top:10px;
}
.section-under-slider p {
    font-size: 20px;
    line-height: 28px;
    font-family: 'Gill Sans' !important;
    font-weight: 400;
}
.subscribe-title h2 {
    font-family: Satisfy !important;
    font-size: 60px;
    line-height: 51px;
    margin-bottom: 28px;
    margin-top: 34px;
}
.homepage-row.flex-parent {
    margin-top:50px;
}
.homepage-row.home-images-wrapper {
    margin-bottom: 50px;
    padding-top:40px;
}
.ecs-column-2.subscribe-text {
    width: 37%;
    margin-right: 3.8%;
}
.ecs-column-2.subscribe-widget {
    width: 59.2%;
}
#signup-tbx {
    width:71%;
    display:inline-block;
}
#signup-btn {
    width:25.36%;
    float:right;
}
#signup-btn:hover {
    color: #000 !important;
    background-color: #ededed !important;
}
.mbss-subscribe-wrapper {
    padding-bottom:0;
}
.mid-footer.link-footer {
    background-color: #ededed !important;
    padding-top:15px;
    padding-bottom:35px;
}
.mid-footer.link-footer .ecs-row{
    display:flex;
    justify-content:space-between;
}
.mid-footer.social-footer {
    background-color: #fff !important;
    padding-top: 25px;
    padding-bottom: 25px;
}
.mid-footer.link-footer .ecs-column-4 a {
    font-size:14px;
    line-height:21px;
    color:#707070!important;
}
.mid-footer h3 {
    margin-bottom:0;
    color:#000;
    font-weight:600;
    font-size:16px;
}
.link-footer .ecs-column-4 {
    width: unset !important;
    text-align:center;
}
.social-link {
    border-radius: 0;
    width: 42px;
    height: 24px;
    margin-right: 0;
    text-align: left;
    padding-left: 10px;
    border: none;
}
.social-text {
    display:inline-block;
}
.ecs-column-3.align-bottom {
    margin-top:15px;
}
.ecs-column-3.align-bottom-img {
    margin-top: 28px;
}
.bottomfooter {
    background-color: #ededed;
}
.bottomfooter p{
    font-size:14px;
    line-height:18px;
    padding-bottom:18px;
}
.home-images-wrapper-mobile {
    display:none;
}
.pf-tablet-banner, .pf-mobile-banner{
    display: none;
}
.tablet-image, .mobile-image {
    display: none!important;
}
.mid-footer .mbss-content h3 {
    margin-bottom: 10px;
    font-weight: 600;
}
/*Showroom*/
.showroom .ecs-row-full-width.homepage-row {
    padding-top: 90px;
    padding-bottom: 90px;
}
.showroom .ecs-column-2 {
    width: 49%;
}

.showroom .ecs-column-3 {
    margin-right: 3%;
    width: 27.5%;
}
.showroom .mbss-listItem2._2.section {
    padding-top: 0px;
    padding-bottom: 10px;
}
.showroomList {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
}
.showroom-item {
    flex: 0 49.1%;
    padding:10px 0;
}
.showroom h4 {
    text-transform: none;
    font-size: 36px;
    font-weight: 100;
}
.showroom .ecs-column-3-2 {
    width: 67%;
    float: right;
    margin-right: 0;
    margin-top: 10px;
}
.showroom h4:last-of-type {
    margin-bottom: 40px;
    margin-top: 8px;
}
.showroom .opening-hours {
    line-height: 30px;
    font-size:20px;
}
.showroom .section.ecs-row-container {
    padding-bottom:40px;
}
.showroom-bottom-images {
    margin-bottom:50px;
}
.showroom .ecs-row-full-width.homepage-row p, .finance p{
    color: #707070;
}
.showroom h4 strong {
    font-family: 'Gill Sans Bold';
    font-weight: 300;
}
.finance h4 {
    margin-bottom:0;
}
/*Deliveries*/
.deliveries h3 {
    color: #707070;
    font-weight: 300;
}
.deliveries h1 {
    margin-bottom:0;
}
.deliveries-text {
    margin-top:30px;
}
.deliveries-text h3 {
    margin-bottom:5px;
    color:#000;
    font-weight:400;
}
.deliveries-row {
    margin-bottom:50px;
}
.deliveries .grey-section {
    padding-top: 40px;
    padding-bottom: 40px;
}
.deliveries .ecs-row-full-width.grey-section p strong {
    font-weight:600;
}
/*Contact Us*/
.contact-us .ecs-column-3-2 {
    margin-right: 0;
    width: 58.5%;
}
.contact-us  .ecs-column-3 {
    width: 35.667%;
}
.contact-us .grey-bg {
    height: 50px;
    padding-left: 10px;
    padding-top: 12px;
    margin-bottom: 25px;
    margin-top: 15px;
    background-color: #ededed;
}
.contact-us .grey-bg p{
    font-size: 20px;
    line-height: 28px;
    color: #707070;
}
.contact-us .control-label, .contact-text p {
    color: #707070;
}
.contact-text h2 {
    margin-bottom: 56px;
    margin-top: 20px;
}
.pf-btn.pf-btn-primary.contact-submit {
    margin-top: 10px;
}
.contact-us .section.ecs-row-container {
    padding-bottom:0;
}
/*Trade application*/
.signup-page-terms .signup-terms-cbx-wrapper .w-checkbox-input {
    position: absolute;
    bottom: 7px;
}
#trade-enquiries-text{
    margin-bottom:5px;
}
.pf-terms-wrapper .signup-terms-cbx-wrapper p a, .pf-terms-wrapper a, .signup-page-terms .pf-terms-wrapper .pf-terms-box a {
    color: #000 !important;
    text-decoration: underline;
}
.pf-terms-wrapper .signup-terms-cbx-wrapper p a:hover, .pf-terms-wrapper a:hover, .signup-page-terms .pf-terms-wrapper .pf-terms-box a:hover {
    color: #f5dae9 !important;
    text-decoration: underline;
}
.trade-form-wrapper {
    margin-bottom:50px;
}
.trade-enquiries #pf-form-confirm-cbx {
    margin-bottom: 17px;
    margin-right: 20px;
    transform: scale(1.8);
    height: unset;
}
.trade-enquiries .create-account-label {
    text-align: center;
    background-color: white;
    width: 32.75%;
    margin: auto;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
}
.trade-enquiries .create-account-label h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    background-color: white;
}
.trade-enquiries form {
    width: 100%;
    border: 1px solid #707070;
    padding: 50px 36px 40px 36px;
    margin: auto;
    margin-top: 14px;
    position:initial;
}
#signupAddressPostcodeLookup {
    margin-top:35px;
}
#create-btn, .trade-enquiries .pf-btn.pf-btn-primary.contact-submit {
    min-width: 304px;
}
.signup-page-terms .g-recaptcha {
    margin-top: 20px;
    float: right;
    margin-bottom: 20px;
}
.sign-up-page h1{
    text-align:center;
}
.sign-up-page .ecs-row-container .ecs-row .w-col.captchamessage {
    width:100%!important;
}
/*International Shipping*/
.international-shipping .ecs-row-full-width.grey-section {
    padding-top: 55px;
    padding-bottom: 60px;
}
.international-shipping img {
    margin-top:10px;
}
/*Find Us*/
.find-us .find-us-row {
    padding-top: 40px;
    padding-bottom:50px;
}
.find-us {
    padding-bottom:0;
}
.find-us-row h1 {
    margin-top: 0;
}
.find-us-row h3 {
    font-size:18px;
    font-weight:600;
}
.find-us-row.show-mobile {
    display: none;
}
.find-us-big-map {
    margin-bottom: -5px;
}
/*Product pages - Product List*/
.pf-slide-item {
    border:none;
}
.product-category.breadcrumb a.pf-normal-link, #pf-product-details-page .breadcrumb a.pf-normal-link {
    color: #707070;
    font-weight: 400;
}
#pf-product-list-page a.pf-normal-link.w--current, #pf-product-details-page a.pf-normal-link:last-child {
    color: #000 !important;
    font-weight: 600;
}
.ecs-row.product-category {
    text-align: center;
}
.filter-select-wrapper{
    display: none;
}
.fav-item-code, .hide-code, .hide-search-option, .pf-product-code, .product-code.pf-product-code {
    display: none;
}
.productwrapper.pf-product-list-item .list-view-add-cart-wrapper {
    display: none;
}
#pf-product-listing .flex-col {
    width: 23.5%;
}
.productwrapper {
    border: none;
}
.pf-product-name {
    font-size: 16px;
    line-height: 28px;
    color: #000;
}
.pf-product-list-item .product-info-match-height .customer-price span, .pf-product-list-item .product-info-match-height .pf-product-price span {
    font-size: 20px;
    line-height:28px;
    color: #000;
}
.pf-load-more-container {
    display: block;
    text-align: center;
}
#pf-product-listing .flex-col:nth-of-type(3n) {
    margin-right: 1%;
}
#pf-product-listing .flex-col:nth-of-type(3n+1) {
    margin-left:1%;
}
#pf-product-listing .flex-col:nth-of-type(4n+1) {
    margin-left: 0;
}
#pf-product-listing .flex-col:last-child, #pf-product-listing .flex-col:nth-child(4n+4) {
    margin-right: 0;
}
h1.productListingMainHeading {
    margin-top: 37px;
    margin-bottom:10px;
}
.pf-product-listing {
    margin-top: 40px;
    padding-bottom:80px;
}
#pf-product-listing .ecs-row-full-width.flex-item {
    justify-content:center;
}
.pf-product-list-item:hover {
    box-shadow: none;
}
.pf-product-listing .flex-col.border-spaced {
    margin-bottom: 30px;
}
.pf-product-list-item .price-sale.m-sale-price, .sale-price {
    color: #c9292b!important;
}
.pf-product-listing .ecs-row-full-width.flex-item {
    margin-bottom:60px;
}
.pf-load-more-btn {
    width: 344px;
    margin-top: 5px;
}
.product-category p {
    font-size:18px;
    line-height:28px;
}
/*Product pages - Product View*/
.pf-qty-btn, .pf-qty-select {
    height: 40px;
}
#pf-product-actions-wrapper {
    border: none;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 10px;
    padding-bottom:10px;
}
#pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty {
    border-radius: 0;
    color: #000 !important;
    background-color: #eee9e7 !important;
    margin-left: 17px;
    height: 40px;
    width: 190px;
    line-height: 30px;
    font-size: 18px;
    font-family: 'Gill Sans Medium' !important;
}
#pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty:hover {
    background-color: #ededed !important;
}
.trust-pilot-image {
    margin-top: 10px;
    max-width: 320px;
}
.pf-add-to-cart-btn .fa-shopping-cart {
    display: none;
}
.pf-spec-item {
    width: 100%;
    display: flex;
    font-weight:600;
}
.product-information-static-list, .pf-product-information-item .w-dropdown-toggle {
    padding-left: 0!important;
}
.pf-product-details-heading,.pf-qty-bespoke {
    font-size: 20px;
    line-height:28px;
    font-weight: 400;
    color: #000;
}
.pf-product-details-heading h1{
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #000;
}
.pf-product-details-info-wrapper span[itemprop=price] {
    color: #000;
    font-size: 36px;
    line-height:50px;
}
#pf-product-details-page .pf-product-image-thumb.w--current {
    border: 1px solid #f5dae9;
    max-height: unset;
}
#pf-product-details-page:not(.product-wide) .pf-product-image-thumb:nth-child(4n+1) {
    margin-left: 0;
    margin-right: .5%;
}
.pf-product-image-thumb-wrapper, .pf-product-main-image-wrapper {
    width: 100%;
}
#pf-product-details-page .pf-product-image-thumb{
    background-color:transparent;
}
#pf-product-details-page .pf-product-image-thumb img {
    height: 100%;
}
.breadcrumb .pf-normal-link:last-child, .pf-product-price {
    color: #000;
}
.breadcrumb .pf-normal-link:last-child {
    font-weight:600;
}
.pf-product-information-item .w-dropdown-list {
    border: none;
}
#pf-product-details-page > .ecs-row:first-child > .ecs-column-2:first-child {
    width: 49%;
    margin-right: 7%;
}
#pf-product-details-page > .ecs-row:first-child > .ecs-column-2:last-child {
    width: 44%;
}
#pf-product-details-page .pf-product-image-thumb {
    border: 1px solid transparent;
}
.pf-spec-item-label {
    min-width: 10%;
    line-height: 28px;
}
.pf-spec-item-value {
    line-height: 28px;
}
.pf-product-details-description {
    margin-top:25px;
}
.pf-product-information-header {
    font-size:16px;
    line-height:28px;
}
.pf-product-information-item .w-dropdown-toggle {
    padding: 10px 15px 10px 0;
}
.pf-related-viewed-section, .pf-recent-viewed-section {
    border:none;
    background-color:#fff;
}
.pf-recent-related-header {
    font-size:30px;
    line-height:42px;
}
.pf-recent-list-wrapper, .pf-related-list-wrapper {
    margin-left:0;
    margin-right:0;
}
#pf-related-recent-products-wrapper .pf-slide-item {
    margin-left: 6px;
    margin-right: 6px;
}
#pf-related-recent-products-wrapper .pf-product-price {
    font-size: 20px;
}
.pf-product-details-heading.show-mobile {
    display:none;
}
.pf-product-variant-item .product-detail-price {
    position: absolute;
    right: 0;
    top: -30px;
}
/*Basket*/
#cartModal.pf-cart-modal .remodal-close {
    left: unset;
    right: 65px;
    top: 24px;
}
#cartModal .remodal-close:before {
    font-size: 70px;
    color: #000;
    left: initial;
    font-family: 'Gill Sans' !important;
}
#checkout-cart thead th {
    background-color: #eee9e7 ;
    border: 1px solid #707070;
    height: 61px;
    font-weight:600;
}
.pf-save-for-later.pull-left.pf-btn.pf-btn-sm.pf-btn-primary, .pf-request-check-stock-btn, .pf-request-check-stock.pull-right.pf-btn.pf-btn-sm.pf-btn-primary {
    background-color: #ededed !important;
    color: #000 !important;
}
.pf-save-for-later.pull-left.pf-btn.pf-btn-sm.pf-btn-primary:hover, .pf-request-check-stock-btn:hover, .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-proceed-checkout-btn:hover, .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock:hover {
        background-color: #f5dae9 !important;
        color: #000 !important;
    }
.pf-continue-shopping-cart:hover {
    background-color: #ededed !important;
    color: #000 !important;
}
.pf-continue-shopping-cart {
    background-color: #ededed !important;
    border: none !important;
    color: #000 !important;
    text-transform: uppercase;
}
#pf-popup-cart-wrapper h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
}
.pf-checkout-cart-table .pf-btn-showAddNote {
    padding: 5px 10px 5px 10px;
    background-color: #fff;
    border: 1px solid #707070;
    color: #707070;
}
.remove-icon {
    width: 21px;
    height: 24px;
    display: block;
    margin: auto;
    background-image: url(/Assets/release/site/css/images/delete.png);
}
#checkout-cart th {
    font-size:18px;
    line-height:26px;
}
.pf-checkout-cart-table .pf-qty-select, .pf-checkout-cart-table .pf-qty-btn.pf-qty-btn-minus, .pf-checkout-cart-table .pf-qty-btn.pf-qty-btn-plus {
    background-color: #ededed;
    background-image: none;
}
.pf-checkout-cart-table th:first-of-type, .pf-checkout-cart-table td:first-of-type {
    border-right: none !important;
}
.pf-checkout-cart-table .cart-description, .pf-checkout-cart-table .cart-description, td.cart-description {
    border-left: none !important;
}
.cart-first img {
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 65px;
    margin-right: 23px;
}
.pf-checkout-cart-table .cart-description {
    width: 20%;
}
.cart-description, td.cart-description {
    text-align: initial;
}
#cartModal {
    max-width: 1200px;
    padding: 58px;
}
.checkout-cart-footer-row.pf-checkout-cart-footer-row .checkout-cart-footer-row-label {
    padding-right: 35px;
}
th.checkout-cart-footer-row-label:last-child {
    padding-left: 35px;
}
.pf-checkout-cart-table td {
    border-color:#707070;
}
.cart-first {
    float: left;
}
#BtnAddPurchaserNote {
    width: 140px !important;
    height: 40px;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    margin-top: 30px;
}
.cart-qty .pf-qty-btn {
    height: 40px;
    width: 40px;
}
.cart-qty .pf-qty-select {
    height: 40px;
    width: 48px;
}
th.price.cart-price, th.cart-remove, th.cart-total-tax {
    width: 11.7%;
}
th.cart-qty {
    width: 18.5%;
}
th.cart-total-tax {
    width:14.2%;
}
th.cart-total-inc-tax {
    width:13.1%;
}
.pf-checkout-cart-table .pf-qty-select {
    font-family: 'Gill Sans';
    font-size: 16px;
    color: #000;
}
td.cart-total-inc-tax {
    font-size: 20px;
    color:#000;
}
th.checkout-cart-footer-row-label {
    text-align: right;
}
.cart-footer-row.pf-checkout-cart-footer-row .checkout-cart-footer-row-label {
    padding-right: 35px;
}
td.cart-price, td.cart-total-tax {
    color:#707070;
}
.pf-checkout-cart-table {
    border-color: #000;
}
#cartModal .cart-buttons-popup a.pf-proceed-checkout-btn, #cartModal .cart-buttons-popup a.pf-request-check-stock {
    min-width: 243px;
}
#cartModal .cart-buttons-popup a.pf-continue-shopping-cart, .pf-save-for-later.pull-left.pf-btn.pf-btn-sm.pf-btn-primary {
    min-width: 215px;
}
#cartModal .cart-buttons-popup a {
    font-size: 16px;
    height: 50px;
    line-height: 42px;
}
.ecs-row-container.cart-buttons-popup {
    float:left;
}
.ecs-row-container.cart-buttons-popup .pf-continue-shopping-cart, .ecs-row-container.cart-buttons-popup .pf-proceed-checkout-btn {
    margin-top: 10px;
}
.request-check-stock-wrapper {
    background-color:#eee9e7;
    padding: 25px 25px 25px 25px;
    margin-top: 52px;
}
.request-check-stock-wrapper .pf-request-check-stock-btn {
    position: absolute;
    right: 0;
    margin-top: 27px;
    background-color: #000!important;
    color: #fff !important;
    font-size:16px;
    line-height:30px;
}
.pf-request-check-stock.slided-up.pull-right.pf-btn.pf-btn-sm.pf-btn-primary {
    background-color: #f5dae9 !important;
}
.request-check-stock-wrapper .w-form {
    margin-top: 30px;
}
/*Checkout*/
#delSaveAddress, #billSaveAddress {
    height:unset;
}
.check-checkout {
    font-size: 20px;
    line-height: 28px;
}
#checkout .postcode-lookup-input, #checkout .postcode-lookup-btn {
    display: inline-block;
    width: 50%;
    height: 40px;
    margin-bottom:6px;
}
.pf-delivery-options-wrapper .delivery-options p {
    color:#707070;
}
.pf-delivery-options-wrapper .delivery-options label {
    margin-bottom:15px;
}
.check-h3.checkout-title, #selectPaymentMethod h3 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 600;
}
#selectPaymentMethod .center {
    text-align: center;
    margin-top: 40px;
    font-weight: 600;
}
#checkout #paymentMethods .paymentLabel input[type=radio], .deliveryOptionRbn {
    transform: scale(1.8);
    margin-left: -36px;
    margin-right: 18px;
    height: unset;
}
.deliveryOptionRbn {
    margin-left:0;
}
.pf-input {
    height:45px;
}
#discount-btn, #makePaymentBtn {
    line-height: 35px;
    height:45px;
}
.cart-buttons-popup .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock {
    line-height:30px;
}
#use-billing-cbx, #terms-cbx, #consent-cbx {
    transform: scale(1.8);
    margin-bottom: 20px;
    margin-right: 18px;
    height: unset;
}
.signup-terms-cbx-wrapper #consent-cbx, .signup-terms-cbx-wrapper #terms-cbx {
    margin-bottom: 17px;
    margin-right: 20px;
    margin-left: -14px;
}
.signup-terms-cbx-wrapper .w-form-label {
    margin-left: 15px;
}
.delivery-options label, .order-total-label, .delivery.order-totals .order-total-value {
    font-size: 20px;
    line-height: 28px;
}
#paymentMethods {
    padding:0 25px;
}
checkout #makePaymentBtn {
    max-width: 180px;
    float: right;
}
.delivery-options p {
    font-size: 18px;
    line-height:26px;
}
.delivery.order-totals .ecs-row {
    margin-bottom:20px;
}
#makePaymentBtn {
    width: 180px;
    float: right;
}
.delivery.order-totals .order-total-value {
    font-weight:600;
}
.pf-btn.pf-btn-colour-one {
    background-color: #eee9e7 ;
}
#pfQoSearchBtn.pf-btn.pf-btn-colour-one:hover {
    background-color: #f5dae9 !important;
    color: #f5dae9 !important;
    border-color: #f5dae9 !important;
}
.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover {
    border-color: #f5dae9;
}
.pf-quick-order-cart-footer .pf-proceed-checkout-btn {
    line-height:30px;
}
#pfQoSearchBtn {
    padding-top:15px;
    height:45px;
}
#checkout h3 {
    font-weight:600;
}
#paymentMethods .proforma-item .paymentLabel:first-of-type {
    margin-bottom:40px;
}
/*MyAccount*/
.w-tab-menu .pf-tab-header.w--current {
    background-color: #eee9e7 ;
    color: #000 !important;
    border: 1px solid #eee9e7 ;
}
.pf-my-orders-table .pf-btn.pf-btn-primary, .pf-my-returns-table .pf-btn.pf-btn-primary {
    color: #000 !important;
    background-color: #eee9e7  !important;
}
.pf-my-orders-table .pf-btn.pf-btn-primary:hover, .pf-my-returns-table .pf-btn.pf-btn-primary:hover {
    background-color: #ededed !important;
}
.pf-all-wishlists .dropdown-toggle.pf-filter-item {
    color: #f5dae9;
}
#pf-my-account-page .pf-btn-primary.pf-btn-with-qty, .pf-add-wishlist-to-cart-btn, .add-btn pf-add-all-wishlists-to-cart-btn, #pf-my-account-page .pf-btn-primary {
    line-height: 24px;
    padding: 8px 15px 0 15px;
}
#pf-my-account-page .reorder-item-btn {
    min-width: 130px;
}
.pf-wishlist-quick-entry {
    display: none;
}
input[type=text].pf-create-wish-list, input[type=text].pf-edit-wishlist {
    padding: 0px 5px 8px 5px;
    height:40px;
}
#cancel-details-btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;
    min-width: 180px;
    line-height: 29px;
}
.style-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}
.style-container .style-item {
    margin: 10px 10px 20px 10px;
    flex: 0 0 21%;
    text-align:center;
}
.create-the-style-arrows {
    color: #eee9e7;
}
.create-the-style-arrows.left {
    left: 0;
}
.create-the-style-slider {
    text-align:center;
}
#pf-gallery-image-products {
    flex-wrap: wrap;
}
div#pf-gallery-image-product-actions .pf-btn-secondary.pf-add-all-to-wish-list-btn {
    background-color: #eee9e7;
    border-color: #eee9e7;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: inherit;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    min-width: 180px;
}
#pf-gallery-image-products .flex-col {
    flex: 1 0 21%;
    margin: 10px;
    padding: 10px;
}
.wishlist-main-buttons .create-wish-List-btn, .wishlist-main-buttons .cancel-new-wishlist-btn {
    height: 40px;
}
.pf-wishlist-create-new input[type=text].pf-create-wish-list, input[type=text].pf-edit-wishlist {
    padding: 8px 5px 8px 5px;
}
#scrollUp {
    background-image: url(/Assets/release/site/css/images/Top.png);
    right: initial;
    left: 64px;
    background-color: transparent;
    height: 70px;
    width: 50px;
    bottom: 40px;
}
#scrollUp:hover {
    background-image: url(/Assets/release/site/css/images/Top-hover.png);
}
#scrollUp i {
    display:none;
}
#cookie-bar p, #cookie-bar .cb-enable.w--current {
    color: #fff!important;
}
.pf-btn.pf-btn-sm.pf-btn-primary.pf-enquire-product {
    background-color: #ededed !important;
    color: #000 !important;
    text-transform: none;
    font-size: 16px;
    line-height: 28px;
}
.pf-product-list-item .pf-btn.pf-btn-sm.pf-btn-primary.pf-enquire-product {
    float: none;
    bottom: unset;
}
.enquire-row {
    display: block;
}
.pf-btn.pf-btn-sm.pf-btn-primary.pf-enquire-product:hover {
    background-color: #f5dae9 !important;
}
.enquire-product-remodal {
    background-color:transparent!important;
}
.enquire-product-remodal h2{
    color:#fff;
}
.enquire-product-remodal .remodal-close {
    left: unset;
    right: 30px;
}
.enquire-product-remodal .remodal-close:before {
    font-size:50px;
    color:#fff;
}
.enquire-btn {
    float: left;
    width: 95px;
    height: 40px;
    background-color: #fff;
    color: #000;
    text-transform: uppercase;
}
input.pf-multi-select-item + label:after {
    border: 1px solid #000;
    color: #000;
}
input.pf-multi-select-item:checked + label:after {
    background-color: #000;
}
.pf-continue-shopping-cart i, .pf-proceed-checkout-btn .fa-lock {
    margin-right: 8px;
}
.pf-request-check-stock i, .pf-proceed-checkout-btn .fa-angle-right {
    margin-left:8px;
}
.cart-first.tablet-only {
    display: none;
}
#pf-my-account-page .pf-btn.pf-btn-sm.pf-btn-primary.create-wish-List-btn, #pf-my-account-page #change-password-btn {
    padding: 8px 15px 8px 15px;
}
#create-account-form .postcode-lookup-btn, .subscribe-widget #signup-btn, .trade-enquiries .postcode-lookup-btn {
    height: 45px;
}
.checkout-cart-footer-row.pf-checkout-cart-footer-row {
    color:#000;
}
.pf-product-information-item .w-dropdown-list.w--open {
    border-bottom:1px solid #ccc;
    border-radius:0;
}
.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    background-color: #eee9e7;
}
.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    background-color: #ededed;
    color:#000!important;
}
.midnav .ecs-row.mobile-flex-col-logo.flex-header-links .header-link p a:hover,
.midnav .ecs-row.mobile-flex-col-logo.flex-header-links .header-link a.w--current {
    color: #000 !important;
}
.about-us .section.ecs-row-container, .downloads .section.ecs-row-container {
    float: inherit;
    max-width: none;
}
.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    background-color:#000;
    color:#fff!important;
}
.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    color: #f5dae9 !important;
}
.about-us .title-wrapper-2.bottom p {
    margin-bottom:20px;
}
.ui-menu-item.ui-state-focus {
    color: #000 !important;
}
.downloads-dropdown-list a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color: #eee9e7;
}

.downloads-dropdown-list a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    color: #707070;
}

.downloads-dropdown-list a.mbss-btn-icon:hover {
    color: #fff;
}
.mobile-logo, .full-width-section.grey-section .showroom-mobile-img, .cart-remove-mobile {
    display: none;
}
.enquire-product-remodal .pf-form-confirm-text, .enquire-product-remodal .form-col {
    color: #fff;
    text-align:start;
}
.enquire-product-remodal #pf-form-confirm-cbx {
    transform: scale(1.8);
    margin-bottom: 20px;
    margin-right: 18px;
    height: unset;
}
.pf-new-product-tag.discon {
    font-size:14px;
}
.shrink .topnav {
    height: 40px;
}
a.categorylink {
    color:#707070;
}
.tag {
    border-color: #707070 !important;
    color: #707070;
}
.tag:hover {
    background: #707070;
}
.wish-list-parent {
    margin-bottom:5px;
}
.header.shrink .topnav {
    display:none;
}
.header.shrink .flex-header-links {
    display: none;
}
.shrink .brand {
    -webkit-transform: translate(-50%, 0) scale(0.8);
    -ms-transform: translate(-50%, 0) scale(0.8);
    transform: translate(-50%, 0) scale(0.8);
}
.breadcrumb.mobile {
    display:none;
}
.showroom .showroom-tablet-img {
    display: none;
}
#selectPaymentMethod h3{
    margin-bottom:36px;
}
#paymentMethods .ecs-column-2:first-of-type .ecs-radio:first-of-type .paymentLabel {
    margin-bottom:36px;
}
#paymentMethods .ecs-column-2:first-of-type .ecs-radio:first-of-type .paymentLabel img{
    margin-top:-15px;
    padding-top: 7px;
}
.mid-footer.link-footer .ecs-column-4 p {
    color: #707070;
    font-size: 14px;
    line-height: 21px;
}
.mega-list .pf-nav-links .heading.w-dropdown-link {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
}
.mega-list .pf-nav-links .dropdownlink.w-dropdown-link {
    font-size: 14px;
    line-height: 22px;
    padding: 3px 0;
}
.mega-list.w-dropdown-list .ecs-row {
    display: flex;
}
.pf-nav-links {
    flex-grow: 1;
}
#payment-failed-page {
    padding-top:250px;
}

.clearance-product-tag {
    font-size: 9px;
}

#pf-product-details-page.product-wide > .ecs-row:first-child {
    max-width: 1460px;
}
#pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:first-child {
    width: 60%;
    margin-right: 5%;
}
#pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:last-child {
    width: 35%;
}
.price-match-form {
    width: 100%;
    border: 1px solid #707070;
    padding: 50px 36px 24px 36px;
    margin: auto;
    margin-top: 14px;
    overflow: auto;
}
.trade-enquiries .create-account-label.price-match {
    margin-bottom: -10px;
}
.webui-popover {
    z-index: 99999 !important;
}
.grey-section.full-width-section.section-under-slider {
    flex-wrap:wrap;
}
.homepage-header h1 {
    font-size: 45px;
    line-height: 58px;
    font-family: 'Gill Sans' !important;
    font-weight: 400;
}
.ecs-row-full-width.homepage-row.homepage-header {
    padding-bottom: 50px;
    padding-top: 10px;
    text-align: center;
}
.nav-mobile-bar {
    display:none;
}
.signup-address-row {
    top:-10px;
}
#orders-tab-content .pf-table.pf-my-orders-table .pf-btn.pf-btn-primary {
    min-width:unset;
}
#orders-tab-content .pf-table.pf-my-orders-table .pf-order-details-total-label, #orders-tab-content .pf-table.pf-my-orders-table [data-label="Items Total "], #orders-tab-content .pf-table.pf-my-orders-table [data-label="Delivery"], #orders-tab-content .pf-table.pf-my-orders-table [data-label="Order Total"] {
    border: none;
}
#create-account-form #pf-address-manual-wrapper {
    margin-top:20px;
}
#checkout-login .site-button.checkout-btn.pf-btn.pf-btn-md.pf-btn-primary {
    margin-top:5px;
}
#orders-tab-content .pf-table.pf-my-orders-table [data-label="Your Ref"] {
   min-height:40px;
}
.pf-favs-wrapper table tr td {
    border:none;
}
.blogpost-wrapper .blog-img-medium {
    height:100%;
    display: block;
    background-position: 50% 50%;
    background-size: cover;
}
    .blogpost-wrapper .blog-img-medium:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
.blog-feed-row {
    padding:20px;
}
.post h1 {
    font-size:50px;
    line-height:58px;
}
.blog-overview .blog-header-section h1 {
   margin-bottom:0;
}
.blog-overview .blog-header-section hr {
    width:50%;
    border:1px solid #000;
    margin-bottom:20px;
}
.blog-overview .blog-header-section {
    width: 50%;
    text-align: center;
    margin: auto;
}
.blog-overview .blog-header-text-section {
    width: 80%;
    text-align: center;
    margin:auto;
}
.blog-overview .blog.section {
    padding-top:50px;
}
.blog-feed-row .w-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.product-detail-description a {
    text-decoration:underline;
    font-weight:600;
}
.blog-details .p ul {
    font-weight:300;
}

.blog-overview .featured-post-row .blog-img-large {
    min-height: unset !important;
    height: 675px;
}
.blog-details .post .p strong {
    font-weight:600;
}
.style-section .section.title-wrapper h1 {
    margin:0;
}
.style-section .section.title-wrapper h2 {
    margin: 0;
    padding:0;
}
.blogpost-text h2 {
    line-height: 38px;
}
.blogpost-text h3 {
    line-height: 32px;
}
#pf-gallery-image-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
}
.container-btns-save-cart {
    margin-top:30px;
}
.pf-save-cart-btn {
    left:-40px;
}
.pf-cart-reference {
    float: left;
    width: 50%;
}
.pf-cart-reference {
    border:1px solid;
    height:40px;
    padding-left:10px;
}
.lobibox-notify.lobibox-notify-info {
    border-color: #fff !important;
    background-color: #fff !important;
    color: #000 !important;
}
.banner-container {
    justify-content: center;
}
.style-section .section.title-wrapper {
    float:none;
    width:30%;
    margin:auto
}
.style-section .section.title-wrapper h1,.style-section .section.title-wrapper h2 {
    margin: auto;
    border-bottom: 1px solid #000;
}
.blog.section .ecs-row-full-width.blog-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
}
.ecs-row-full-width.blog-list .ecs-column-2 {
    margin-right: unset !important;
    width:100%;
}
.w-nav-menu .mega-nav-images div.pf-nav-image img {
    max-width: 270px;
    max-height: 270px;
    height: auto; 
    width: auto;
    margin-right:0;
}
.pf-nav-image.mega-col {
    padding:10px;
}
.pf-nav-images {
    flex-grow:0;
}
.mega-list .pf-nav-links .ecs-nav-group span {
    width: inherit;
    display: block;
    word-break: break-word;
    white-space: normal;
}
.ecs-nav-group {
    break-inside:avoid;
    -webkit-column-break-inside:avoid;
    page-break-inside:avoid;
    padding-bottom:0;
}
.pf-product-main-image-wrapper img {
    width: 100%;
    height: 100%;
}
.pf-list-item-img {
    width: 100%;
    height: 100%;
}
.pf-gallery-view-image {
    width: 100%;
    height:auto;
}
.pf-placeholder {
    position: relative;
    overflow: hidden;
    background: #fff;
}

.pf-placeholder::before {
    content: '';
    display: block;
    position: absolute;
    left: -30%;
    top: 0;
    height: 100%;
    width: 30%;
    background: linear-gradient(to right, transparent 0%, #ededed 50%, transparent 100%);
    animation: placeholder-loading 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes placeholder-loading {
    0% {
        left: -30%;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

.pf-placeholder.pf-list-item-img {
    width: 100%;
    height: auto;
    padding-top: 100%;
}
strong {
 font-weight:600;
}
.banner-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
}
.paypal-cart {
    float: right;
    min-width: 370px;
    margin: 10px;
}
.paypal-message-checkout {
    margin-left:15px;
}
.midnav .nav-menu.w-nav-menu .w-dropdown .dropdown-icon {
    display:none;
}
.nav-menu .nav-link.nav-dropdown, .shrink .nav-link.nav-dropdown.w--open {
    padding: 9px 12px 10px!important;
}
.nav-menu .nav-link.w-nav-link {
    padding: 9px 12px 10px;
}
#pf-product-list-page {
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
}
#pf-product-list-page .breadcrumb{
    order:1;
}
#pf-product-list-page .productListingMainHeading{
    order:2;
}
#pf-product-list-page .product-listing-category-description{
    order:3;
}
#pf-product-list-page #pf-product-listing{
    order:4;
}
.mobile-only-homepage {
    display:none;
}
#pf-product-listing .row-3-col.flex-col {
    width: 31.6%;
}
#pf-product-listing .row-3-col.flex-col:nth-of-type(3n) {
    margin-right: 0;
    margin-left: 1%;
}
#pf-product-listing .row-3-col.flex-col:nth-of-type(3n+1) {
    margin-left: 0;
    margin-right:1%;
}
#pf-product-listing .row-3-col.flex-col:nth-of-type(3n+2) {
    margin-left: 1%;
    margin-right:1%;
}
.new-right-top.pf-new-product-tag.limited, .new-right-top.pf-new-product-tag.discon.discount-badge {
    line-height: 8px;
}
.badge-top-line, .badge-bottom-line {
    font-size: 14px;
    position: relative;
    display: inline-block;
}
.badge-top-line {
    top: 17px;
}
.badge-bottom-line {
    top:24px;
}
.payment-button-row {
    text-align:center;
}
#makePaymentBtn {
    width: 40%;
    float: unset;
    line-height: 40px;
    height: 50px;
    font-size:18px;
}
#pf-product-details-page .trustpilot-widget {
    float: left;
    margin-top: 20px;
    margin-left:-18px;
}
.lg-outer .lg-toogle-thumb {
    right:unset;
    left:20px;
}
.product-information-static-list li {
    text-indent: -1.4em;
    padding-left: 1.3em;
}
.lg-outer .lg-sub-html {
    display:none;
}
.nav-menu.top .mbss-text ul.redactor-toolbar {
    top:24px!important;
}

@media screen and (max-width: 1280px) and (min-width:1025px) {
    .pf-product-main-image-wrapper .w-tab-pane {
        text-align:center;
    }
    .pf-product-main-image-wrapper img {
        width: unset;
        max-height: 350px;
    }
}
@media screen and (min-width: 1025px) {
    #pf-product-details-page.product-wide .pf-product-image-thumb {
        width: 18.4%;
        margin-left: 1%;
        margin-right: 1%;
    }
    #pf-product-details-page.product-wide .pf-product-image-thumb:nth-child(5n+1) {
        margin-left: 0;
    }
    #pf-product-details-page.product-wide .pf-product-image-thumb:nth-child(5n) {
        margin-right: 0;
    }
}

@media screen and (max-width: 1440px) {
    .showroom-item img {
        height: 100% !important;
    }
    .showroom .ecs-column-3 {
        width: 34%;
    }

    .showroom .ecs-column-3-2 {
        width: 63%;
    }
    .showroom .ecs-column-3-2 img {
        max-height: 294px;
        height: 19vw;
    }
    .ecs-row-container, .international-shipping .ecs-row-full-width, .ecs-main-container.pf-product-list-page {
        max-width: 1230px;
        padding-left: 30px;
        padding-right: 30px;
    }
    #pf-product-details-page {
        max-width: 1230px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .slider {
        max-width: 1200px;
    }
    .homepage-row:not(.top-slider-homepage) {
        max-width: 1260px;
    }
    .ecs-main-container-nbp {
        padding-top:260px;
    }
    .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
        padding-top: 290px;
    }
    .section-under-slider .ecs-column-2:first-of-type {
        width: 41%;
    }

    .section-under-slider .ecs-column-2:last-of-type {
        width: 53.3%;
    }
    .contact-us .grey-bg {
        margin-bottom: 15px;
    }
    .contact-text h2 {
        margin-bottom: 46px;
        margin-top: 20px;
    }
    .find-us .find-us-row {
        padding-top: 20px;
        padding-bottom: 80px;
    }
    #pf-product-listing .w-col, #pf-related-recent-products-wrapper .w-col {
        padding-left: 0;
        padding-right: 0;
    }

    .slider-bottom-wrapper .slider {
        min-height: 300px;
        max-height: 380px;
        height: 30vw;
    }

    #pf-product-details-page.product-wide {
        max-width: 1380px;
    }
    #pf-product-details-page.product-wide > .ecs-row:first-child {
        max-width: 1350px;
    }
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:first-child {
        width: 59%;
        margin-right: 2%;
    }
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:last-child {
        width: 39%;
    }
    .slider-main-banner-wrapper .slider {
        max-height: 444px;
    }

    .blog-overview .featured-post-row .blog-img-large {
        min-height: unset !important;
        height: 52vw;
        max-height: 620px;
    }
}
@media screen and (max-width: 1258px) {
    .nav-menu.top .ecs-column-2 .text-right {
        display:none;
    }
    .nav-menu.top .ecs-column-2 {
        width: 30%;
    }
    .nav-menu.top .ecs-column-2:last-child {
        left: 2%!important;
    }
    #pf-product-details-page {
        padding-left: 30px;
        padding-right: 30px;
    }
    .remove-icon {
        display: inline-block;
    }
    .cart-description .cart-remove {
        display:none;
    }
    #signup-btn {
        min-width: unset !important;
    }
    .pf-my-orders-table .pf-btn.pf-btn-primary, .pf-my-returns-table .pf-btn.pf-btn-primary {
        min-width:unset;
    }
}
@media screen and (max-width: 1024px) and (min-width:768px) {
    .style-container .style-item:last-of-type {
        flex: 0 44% !important;
    }
    .nav-menu.top .ecs-column-2 {
        display:none;
    }
    .shrink .topnav, .shrink .mobile-flex-col-logo.flex-header-links {
        display: none;
    }
    .shrink .brand {
        transform: scale(0.9);
    }
    .shrink #search-form {
        position: absolute;
        width: 23%;
        top: 10px;
        padding:0;
    }
    .shrink .midnav .ecs-row.mobile-flex-col-logo {
        padding:0;
    }
    .home-second-logo-wrapper p {
        font-size:18px;
        line-height:26px;
    }
    .social-icon {
        margin-left:5.5%;
    }
    .showroom-item img {
        height: auto;
    }
    .deliveries-row .ecs-column-2:last-of-type div, .deliveries-row img {
        width: 100%;
    }
    #selectPaymentMethod .center {
        margin-top: 0;
        font-weight: 400;
    }
    #BtnAddPurchaserNote {
        margin-bottom: 20px;
    }
    .checkout-cart-footer-row.pf-checkout-cart-footer-row:last-of-type th.checkout-cart-footer-row-label {
        display:table-cell;
    }
    .w-nav-menu .mega-nav-images div.pf-nav-image img {
        max-width: 200px;
        max-height: 200px;
        height: initial;
        width: initial;
    }
    .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
        padding-top: 275px !important;
    }
}
@media screen and (max-width: 1024px) {
    .mega-list.w-dropdown-list {
        max-width:1024px;
    }
    .blog.section .ecs-row-full-width.blog-list {
        grid-template-columns: 1fr 1fr;
    }
    .pf-nav-images {
        display:none;
    }
    .pf-nav-links {
        width:100%!important;
    }
    .homepage-header h1 {
        font-size: 34px;
        line-height: 44px;
    }
    .ecs-row.product-category {
        display:block;
    }
    .showroom .ecs-column-3-2 img {
        display: none;
    }
    .showroom .ecs-column-3-2 .showroom-tablet-img  {
        display: block;
    }
    .showroom .ecs-column-3-2 .showroom-tablet-img img {
        display: inline-block;
    }
    .showroom .ecs-column-3, .showroom .ecs-column-3-2 {
        width: 48.5%
    }
    .find-us-row iframe {
        min-height: 627px;
    }
    #cookie-bar {
        padding-bottom: 65px !important;
    }
    .homepage-row.home-images-wrapper {
        padding-top: 25px!important;
    }
    #checkout .postcode-lookup-input, #checkout .postcode-lookup-btn {
        width: 100%;
        max-width:unset;
    }
    #totalcart {
        border-right: 1px solid #000;
    }
    .ecs-main-container {
        padding-top: 295px;
        padding-bottom: 40px;
    }
    .homepage-row.flex-parent {
        margin-top: 25px;
    }
    .homepage-row.home-images-wrapper {
        margin-bottom: 25px;
    }
    .pf-desktop-banner,.desktop-image {
        display: none!important;
    }
    .tablet-image {
        display:block!important;
    }
    .pf-tablet-banner {
        display: block;
        padding-top: 246px;
    }
    .midnav, .ecs-main-container.finance .ecs-row, .ecs-main-container.contact-us .ecs-row, .ecs-main-container.trade-enquiries .ecs-row, .ecs-main-container.legal-template .ecs-row, .ecs-main-container.ecs-page-wrapper .ecs-row, .ecs-main-container.pf-page-wrapper .ecs-row, .ecs-main-container-nbp.international-shipping .ecs-row, .ecs-main-container.find-us .ecs-row, .ecs-main-container.single-page-checkout .ecs-row{
        padding: 0;
    }
    .ecs-row-container {
        max-width: 100%;
    }
    .homepage-row:not(.top-slider-homepage), .ecs-row:not(.flex-header-links), .ecs-main-container.pf-product-list-page {
        max-width: 994px;
        padding: 0 30px;
    }
    .ecs-main-container.pf-product-list-page {
        padding-top:295px;
    }
    .topnav p {
        font-size:14px;
        line-height:20px;
    }
    .bag-interaction .pf-hide-mobile {
        display: none;
    }
    #search-form {
        position: relative;
        top: initial;
    }
    .nav-menu .nav-link.w-nav-link {
        font-size: 12px;
    }
    .nav-menu .nav-link.w-nav-link, .nav-link.nav-dropdown.w-dropdown-toggle {
        font-size: 10px;
    }
    .shrink .nav-link.nav-dropdown.w--open {
        padding: 6px 22px 6px 10px !important;
        font-size: 12px;
    }
    .brand {
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        float: none;
        left: initial;
        text-align: center;
        padding-top: 5px;
    }
    .brand img {
        height: 66px;
        max-width: 344px;
    }
    .ecs-row.mobile-flex-col-logo {
        margin:0;
    }
    #bag-opener {
        margin-right:unset;
        top:5px;
    }
    .top-nav-links {
        float:none;
    }
    .flex-header-links {
        margin: auto !important;
        margin-top: 10px !important;
        margin-bottom: 16px !important;
    }
    .w-dropdown-link {
        padding:4px 0;
        font-size:14px;
    }
    .heading.w-dropdown-link {
        font-size:14px;
        margin-bottom:8px;
    }
    .mega-list .ecs-row {
        max-width:1024px;
    }
    .nav-link.nav-dropdown.w-dropdown-toggle .dropdown-icon > i {
        font-size: 16px;
    }
    .section-under-slider h1 strong {
        font-size: 40px;
        line-height: 56px;
    }
    .section-under-slider h1 {
        font-size: 36px;
        line-height: 46px;
        margin:0;
    }
    .section-under-slider .ecs-column-2 {
        width: 48% !important;
        margin-right: 4%;
    }
    .section-under-slider h3 {
        margin:0;
    }
    .section-under-slider {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .section-under-slider h2 {
        font-size: 30px;
        line-height: 42px;
    }
    .section-under-slider p {
        font-size: 18px;
        line-height: 26px;
        font-family: 'Gill Sans Medium' !important;
        font-weight: 400;
    }
    .section-under-slider .ecs-column-2{
        width:47.25%;
    }
    .homepage-row.flex-parent .flex-child.extra-small {
        width:32.5%;
    }
    .ecs-row-full-width.homepage-row.flex-parent {
        margin-top:25px;
    }
    .full-width-section.pink-section {
        margin-top:10px;
        margin-bottom:20px;
    }
    .showroom .opening-hours {
        line-height: 20px;
        font-size: 16px;
    }
    .showroom .section.ecs-row-container h2.grey-text {
        font-size:20px;
        line-height:30px;
    }
    .showroom .ecs-row-full-width.homepage-row {
        padding-top: 50px;
        padding-bottom: 25px;
    }
    .showroom-item {
        flex: 100%;
    }
    .showroom-bottom-images {
        padding:0;
    }
    .showroom-item img {
        width:100%;
    }
    .showroom .ecs-row-full-width.homepage-row img {
        height:100%;
    }
    .showroom-bottom-images {
        margin-bottom: 0;
    }
    .deliveries-row .ecs-column-2{
        width:100%;
    }
    .deliveries-row img {
        margin-top:50px;
    }
    .deliveries-row {
        margin-bottom: 20px;
    }
    .deliveries .grey-section {
        padding-top:0;
        padding-bottom: 20px;
    }
    .deliveries .grey-section h1 {
        font-size:24px;
        line-height:34px;
    }
    .contact-us .ecs-column-3-2, .contact-us .ecs-column-3 {
        width: 100%;
    }
    .contact-text h2 {
        margin-bottom: 15px;
        margin-top:0;
    }
    .contact-us .pf-btn.pf-btn-primary {
        min-width:295px;
    }
    .contact-us .grey-bg {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .trade-enquiries .create-account-label {
        width: 48.75%;
    }
    .trade-enquiries .pf-btn.pf-btn-primary.contact-submit {
        margin-bottom:20px;
    }
    .ecs-row.trade-form-wrapper {
        padding:0;
    }
    .trade-enquiries #create-account-form, .trade-enquiries form {
        padding: 50px 10px 0 10px;
    }
    .find-us .find-us-row {
        padding-bottom: 30px!important;
    }
    .find-us {
        padding-bottom:0;
    }
    #pf-product-listing .flex-col {
        width: 32%;
    }
    #pf-product-listing .flex-col:nth-of-type(4n+1) {
        margin-right: 1%;
        margin-left: 1%;
    }
    #pf-product-listing .flex-col:nth-child(4n+4) {
        margin-left: 1%;
    }
    #pf-product-listing .flex-col:last-child, #pf-product-listing .flex-col:nth-child(4n+4) {
        margin-right: 1%;
    }
    #pf-product-listing .flex-col:nth-of-type(3n+1) {
        margin-left: 0;
    }

    #pf-product-listing .flex-col:last-child, #pf-product-listing .flex-col:nth-child(3n+3) {
        margin-right: 0;
    }
    h1.productListingMainHeading {
        margin-top: 30px;
    }
    .product-category p {
        font-size: 16px;
        line-height: 22px;
    }
    .pf-product-listing {
        margin-top: 0;
        padding-bottom: 40px;
    }
    #pf-product-details-page .pf-product-image-thumb.w--current {
        height: unset!important;
    }
    #pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty {
        width: unset;
        margin-left: 9px;
    }
    .pf-product-information-delivery-options-header {
        font-size:20px;
        line-height:28px;
    }
    #pf-product-details-page .pf-product-image-thumb {
        width: 32%;
    }
    #pf-product-details-page .pf-product-image-thumb:nth-child(3n) {
        margin-left: 1%;
    }
    #pf-product-details-page {
        padding-top:265px;
    }
    #pf-product-details-page > .ecs-row:first-child > .ecs-column-2:first-child {
        margin-right: 3%;
    }
    .social-text {
        width: 100%;
    }
    .social-link {
        margin-top:0;
    }
    .ecs-column-3.align-bottom {
        margin-top: 8px;
    }
    .ecs-column-3.align-bottom-img {
        margin-top: 15px;
    }
    th.cart-qty, th.cart-remove, th.cart-price, td.cart-qty, td.cart-remove, td.cart-price, .pf-checkout-cart-footer-row th.checkout-cart-footer-padding-remove, th.checkout-cart-footer-padding-total-tax, th.checkout-cart-footer-padding-price, th.checkout-cart-footer-padding-qty {
        display: table-cell;
    }
    #checkout-cart .pf-mobile-qty-wrapper, #checkout-cart .cart-description .cart-price {
        display: none;
    }
    #cartModal {
        padding: 30px;
    }
    #cartModal.pf-cart-modal .remodal-close {
        right:40px;
    }
    #checkout-cart td.cart-description {
        padding-top: 40px !important;
        padding-left: 10px !important;
    }
    .cart-first img {
        margin-top: 0;
        margin-left: 29px;
        margin-bottom: 0;
        margin-right: 27px;
    }
    #checkout-cart thead th {
        height:45px;
    }
    .request-check-stock-wrapper .ecs-row.text-align-center {
        max-width:100%;
    }
    #checkout .cart-first img {
        top:185px;
    }
    .deliveryOptionRbn {
        margin-left: 5px;
    }
    .pf-checkout-actions .ecs-row:last-of-type {
        padding:0;
    }
    .pf-product-variant-item .ecs-row {
        padding:0;
    }
    .pf-product-variant-item .product-detail-price {
        position: relative !important;
        right: 0;
        top: 0;
    }
    .pf-product-details-variant-table .pf-product-details-price-wrapper span[itemprop=price] {
        font-size: 24px !important;
        line-height: 34px !important;
    }
    .pf-related-product-zone {
        max-width:1024px;
        padding:0 30px;
    }
    .quick-order .cart-first img {
        top:325px;
    }
    .style-container .style-item {
        flex: 0 44%;
    }
    .nav-menu .nav-link.w-nav-link {
        padding: 6px 10px 6px;
    }
    .nav-menu .nav-link.nav-dropdown {
        padding: 6px 6px 6px !important;
    }
    .nav-container .nav-link .dropdown-icon {
        right: 6px;
        top: 3px;
    }
    #list-products-wrapper {
        width:100%!important;
    }
    #signup-tbx, #signup-btn {
        width: 100%;
    }
    .ecs-main-container.trade-enquiries .w-col-12 {
        width:100%!important;
    }
    #pf-product-details-page > .ecs-row:first-child > .ecs-column-2:last-child,
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:last-child {
        width: 48%;
    }
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:first-child {
        width: 49%;
        margin-right: 3%;
    }
    .cart-first.tablet-only {
        display:block;
    }
    .pf-checkout-cart-table td:first-of-type, .pf-checkout-cart-table th:first-of-type {
        display: none;
    }
    #BtnAddPurchaserNote {
        margin-top:0;
    }
    .ecs-column-2.pf-addresses-wrapper {
        width:100%;
    }
    .pf-checkout-actions .ecs-row.pf-terms-wrapper {
        padding: 15px !important;
    }
    .trade-enquiries .w-col.w-col-12 {
        text-align:left;
    }
    #create-account-form .signup-page-terms .ecs-row.pf-terms-wrapper {
        padding: 15px;
    }
    #pf-my-account-page .reorder-item-btn {
        min-width:unset;
    }
    .pf-table.pf-order-details-table th:nth-child(2) {
        width:150px;
    }
    .pf-table.pf-order-details-table th:nth-child(7) {
        width: 115px;
    }
    .mega-list.megalist-loggedin.w-dropdown-list .ecs-row {
        padding:0 15px;
    }
    .mega-list.megalist-loggedin.w-dropdown-list {
        min-width:100%;
    }
    .login-column {
        width:100%;
    }
    .slider-main-banner-wrapper .slider {
        min-height: 290px;
        max-height: 378px;
        height: 37vw;
    }
    .slider-bottom-wrapper .slider {
        min-height: 200px;
        max-height: 240px;
        height: 24vw;
    }

    .top-footer.ecs-bottom-container > .ecs-row {
        padding: 0 10px;
    }
    .top-footer.ecs-bottom-container .mbss-subscribe-wrapper > .ecs-row {
        padding: 0;
    }
    .subscribe-title h2 {
        font-size: 48px;
        margin-bottom: 9px;
        margin-top: 28px;
    }
    .ecs-column-2.subscribe-text {
        width: 46%;
        margin-right: 3%;
    }
    .ecs-column-2.subscribe-widget {
        width: 51%;
    }
    .subscribe-text p {
        font-size: 17px;
        line-height: 25px;
    }
    .subscribe-widget .mbss-subscribe-wrapper {
        margin-top: -40px;
    }

    .featured-post-row.ecs-row, .blog-overview > .ecs-row-container {
        padding-left: 0;
        padding-right: 0;
    }
    .blog-overview .featured-post-row .blog-img-large {
        min-height: 52vw !important;
        height: auto;
        max-height: none;
    }
    .full-width-section.pink-section .slider-bottom-wrapper .homepage-row.top-slider-homepage .w-slide {
        background-size: contain !important;
    }
}
@media screen and (max-width: 960px) {
    .find-us-row iframe {
        min-height: 667px;
    }
}
@media screen and (max-width: 850px) {
    .find-us-row iframe {
        min-height: 687px;
    }
}
@media screen and (max-width: 790px) {
    .find-us-row iframe {
        min-height: 727px;
    }
}
@media only screen and (max-width: 1024px) and  (orientation: portrait) {
    .ecs-column-4-3 .post {
        text-align: left;
    }
    .mega-list.w-dropdown-list .ecs-row {
        display: table;
    }
}
@media screen and (max-width: 767px) {
    #pf-product-listing .row-3-col.flex-col:nth-of-type(2n+1) {
        margin-left: 0;
        margin-right: 1%;
    }

    #pf-product-listing .row-3-col.flex-col:nth-of-type(2n+2) {
        margin-left: 1%;
        margin-right: 0;
    }
    .mobile-only-homepage {
        display:block;
    }
    .desktop-tablet-only-homepage, .hide-mobile {
        display: none;
    }
    #pf-product-list-page .breadcrumb {
        order: 1;
    }
    #pf-product-list-page .productListingMainHeading {
        order: 2;
    }
    #pf-product-list-page .product-listing-category-description {
        order: 4;
        margin-bottom:40px;
    }
    #pf-product-list-page #pf-product-listing {
        order: 3;
    }
    .news.section.full-width-section .blog-feed-row .w-col-3 {
        width: 100% !important;
    }
    .cart-buttons-popup .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock {
        text-align: center;
    }
    .style-container .style-item:last-of-type {
        flex: 0 100% !important;
    }
    .cart-buttons-popup .pf-request-check-stock {
        width: 100% !important;
    }
    .ecs-row-full-width.homepage-row.homepage-header h2 {
        margin-bottom: 2px;
    }
    #cartModal .cart-buttons-popup a.pf-save-for-later{
        text-align:left;
    }
    .style-section .section.title-wrapper {
        width: 50%;
    }
    #pf-currency-selector {
        padding: 4px 6px 4px;
    }
    #pf-gallery-image-products .flex-col {
        margin: 0;
        padding:0;
    }
    .style-slide {
        margin-right: -30px;
        margin-left: -30px;
    }
    #pf-gallery-image-products .flex-col:nth-child(2n+2) {
        margin-left:2%;
    }
    #pf-gallery-image-products {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
    .ecs-column-4-3 .post {
        text-align:left;
    }
    .featured-post-row.ecs-row, .ecs-main-container.ecs-row-container.blog-details .ecs-row {
        padding: 0;
    }
    .post h1 {
        font-size: 40px;
        line-height: 48px;
    }
    .blog-overview .blog-header-section {
        width: 80%;
    }
    .blog-overview .blog-header-text-section {
        width: 100%;
    }
    .blog-overview > .ecs-row-container {
        margin-left: -15px;
        margin-right: -15px;
        width: auto;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .blog-details .post figure {
        margin-left: 0;
        margin-right: 0;
    }

    #pf-currency-selector {
        padding: 5px 12px 5px;
    }
    .nav-mobile-bar {
        display: block;
    }
    .nav-mobile-bar .hidemobile {
        display:none;
    }
    .nav-mobile-bar .nav-link.w-nav-link {
        padding: 2px 6px;
    }
    .nav-mobile-bar .nav-link.w-nav-link.w--current {
        color: #f5dae9!important;
    }
    .nav-mobile-bar .nav-link.w-nav-link .fal{
        font-size:25px;
    }
    #paymentMethods .ecs-column-2:first-of-type .ecs-radio:first-of-type .paymentLabel img {
        margin-top: 0;
    }
    .showroom-tablet-img {
        display: none;
    }
    .find-us-row iframe {
        min-height: unset;
    }
    .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock.slided-up .far.fa-angle-up {
        right: 15px;
        bottom: 15px;
        position: absolute;
    }
    .pf-highlight-nav-wrapper .heading.w-dropdown-link.pf-multi-level-dropdown span.pf-highlight-nav {
        margin-bottom: 0 !important;
    }
    .breadcrumb.mobile, #product-media-holder {
        display: block !important;
    }
    #product-media-holder-2, .breadcrumb {
        display: none!important;
    }
    .pf-proceed-checkout-btn.pull-right.pf-btn.pf-btn-sm.pf-btn-primary {
        background-color:#eee9e7 !important;
        border: none !important;
        color: #000 !important;
    }
    .pf-proceed-checkout-btn.pull-right.pf-btn.pf-btn-sm.pf-btn-primary:hover {
        background-color: #ededed !important;
        color: #000 !important;
    }
    #checkout .check-checkout {
        text-align: center;
    }
    .homepage-row.home-images-wrapper {
        padding-top: 0!important;
    }
    h3 {
        font-size: 20px;
        line-height: 18px;
    }
    .blogpost-text h3 {
        line-height: 28px;
    }
    .ecs-main-container {
        padding-top: 215px !important;
        padding-bottom: 30px;
    }
    .ecs-main-container-nbp {
        padding-top: 208px !important;
    }
    .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp, .the-website.pf-promotion-strip-enabled .ecs-main-container {
        padding-top: 218px !important;
    }
    .pf-desktop-banner, .pf-tablet-banner, .tablet-image {
        display: none!important;
    }
    .pf-mobile-banner {
        display: block;
        padding-top: 188px !important;
        margin-bottom:30px;
    }
    .mobile-image {
        display: block !important;
        padding-top: 10px;
    }
    .banner-btn.editMainbanner:not(.add-main-banner), .banner-btn.delete-main-banner {
        left: 100px;
    }
    input, .input, .w-input, #prod-search-tbx {
        height: 40px;
    }
    .topnav, .nav.w-nav, .w-nav[data-collapse=small] .w-nav-menu, .search-opener {
        display: none;
    }
    #search-form {
        display: block;
        padding-bottom: 2px;
    }
    .sidenav-btn-wrapper {
        position: absolute;
        right: 12px;
        top: 0;
        color: #000;
        margin-top:10px;
    }
    .bag-interaction .top-nav-links {
        display:block;
    }
    .nav-mobile-bar {
        height: 32px;
        background-color: #fff;
    }
    .midnav {
        border-top: 1px solid #efefef;
        margin-top: -1px;
    }
    #bag-opener.nav-link.mid {
        margin-top: 0;
        top: -30px;
        right: -6px !important;
    }
    .header-link {
        width:80px;
    }
    .ecs-row.mobile-flex-col-logo {
        width: 100%;
        padding: 0;
    }
    .brand img {
        max-width: 220px;
        height: 100%;
        padding-top: 2px;
        padding-bottom: 5px;
    }
    .brand {
        padding-top:0;
        padding-bottom:0;
    }
    .header .midnav .ecs-row {
        padding: 0 8px;
    }
    .top-footer.ecs-bottom-container .ecs-row {
        padding:0;
    }
    .sidenav-btn-wrapper.menu-button.sidenav-btn .far {
        font-weight: 300;
    }
    #scrollUp {
        left:5px;
    }
    .home-images-wrapper {
        display: none;
    }
    .section-under-slider .homepage-image-container {
        width: 100% !important;
    }
    .section-under-slider .ecs-column-2:first-of-type {
        width:100%!important;
        text-align:center;
    }
    .section-under-slider {
        padding-top: 0;
        padding-bottom: 18px;
    }
    .home-images-wrapper-mobile {
        display: block;
        text-align: center;
        padding:0!important;
    }
    .home-image-mobile {
        margin-bottom:15px;
    }
    .home-image-mobile img{
       width:100%;
    }
    .grey-section-mobile {
        background-color: #ededed;
    }
    .overlay-white {
        background-color: white;
        width: 80%;
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: -40px;
        padding: 15px;
    }
    .overlay-white a, .overlay-white p, .overlay-white h1, .overlay-white h2, .overlay-white h3, .overlay-white h4 {
        font-family: Montserrat!important;
    }
    .pf-mobile-banner .top-slider-homepage .slider-container a, .pf-mobile-banner .top-slider-homepage .slider-container p {
        font-family: 'Libre Baskerville' !important;
        color: #fff;
        font-size:18px;
        line-height:24px;
    }
    .pf-mobile-banner .top-slider-homepage .slider-container .banner-text-small, .pf-mobile-banner .top-slider-homepage .slider-container banner-text-medium, .pf-mobile-banner .top-slider-homepage .slider-container .banner-text-large {
        font-family: 'League Spartan' !important;
        color: #fff;
    }
    .slider-container{
        top: 30%;
        bottom:unset;
        padding-top:0;
    }
    .slider-container ul.mbss-textArea-btns {
        top:-10px!important;
    }
    .slider-container .redactor-toolbar-box ul.redactor-toolbar{
        left: -10px !important;
    }
    .home-images-wrapper-mobile .home-image-mobile .redactor-toolbar-box ul.redactor-toolbar {
        left: -42px !important;
    }
    .slider-container .redactor-toolbar-box ul.redactor-toolbar li a.re-button-icon, .home-images-wrapper-mobile .home-image-mobile .redactor-toolbar-box ul.redactor-toolbar li a.re-button-icon {
        padding: 12px 5px;
    }
    .pf-mobile-banner .slider-container {
        background-image: none;
    }
    .pf-mobile-banner .slider-container .w-container{
        color:#fff;
    }
    .banner-text-large {
        font-size:44px;
        line-height:36px;
    }
    .banner-text-medium {
        font-size: 38px;
        line-height: 30px;
    }
    .banner-text-small {
        font-size: 32px;
        line-height: 26px;
        margin-bottom:10px;
    }
    .homepage-row.flex-parent .flex-child.extra-small {
        width: 100%;
    }
    .homepage-row.flex-parent .flex-child.extra-small:first-of-type {
        margin-top:20px;
    }
    .flex-child, .flex-child.extra-small {
        margin-bottom:18px;
    }
    .full-width-section.pink-section {
        display:none;
    }
    .subscribe-title h2 {
        font-size: 24px;
        line-height: 21px;
        margin-bottom: 10px;
        margin-top: 15px;
    }
    .ecs-column-2.subscribe-text {
        width: 100%;
        margin-right: 0;
        margin-bottom:10px;
    }
    .subscribe-text p {
        font-size: inherit;
        line-height: inherit;
    }
    .ecs-column-2.subscribe-widget, #signup-tbx, #signup-btn {
        width: 100%;
    }
    .subscribe-widget .mbss-subscribe-wrapper {
        margin-top: 0;
    }
    #signup-btn {
        width: 100%;
        font-size: 16px;
        line-height: 21px;
    }
    #signup-tbx {
        margin-bottom:5px;
    }
    .top-footer {
        text-align:center;
    }
    .mid-footer .mbss-content h3 {
        font-size:14px;
        line-height:21px;
    }
    .link-footer p, .link-footer p a{
        font-size: 15px;
        line-height: 21px;
    }
    .mid-footer.social-footer {
        padding-top:0;
    }
    .ecs-column-3.align-bottom {
        margin-bottom:15px;
    }
    .bottomfooter p {
        font-size: 12px;
    }
    .social-text {
        display:block;
    }
    .ecs-row-full-width.homepage-row.flex-parent {
        margin-top: 0;
    }
    .mid-footer h3 {
        font-size: 16px;
    }
    .mid-footer.link-footer .ecs-row {
        flex-wrap: wrap;
        padding:0;
    }
    .link-footer .ecs-column-4 {
        width: 50% !important;
    }
    section-under-slider h1 strong {
        font-size: 30px;
        line-height: 42px;
    }
    .section-under-slider h1 {
        font-size: 24px;
        line-height: 30px;
    }
    .section-under-slider h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .section-under-slider h3 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .section-under-slider p {
        font-size: 14px;
        line-height: 20px;
    }
    .showroom .ecs-row-full-width.homepage-row {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .showroom .ecs-column-3-2 {
        width: 100%;
        margin-top: 20px;
    }
    .showroom .section.ecs-row-container h2.grey-text {
        line-height: 20px;
    }
    .showroom .section.ecs-row-container {
        padding-bottom: 10px;
        padding-top:0;
    }
    .deliveries .grey-section h1 {
        line-height: 24px;
    }
    .deliveries .grey-section {
        padding-left: 15px;
        padding-right: 15px;
    }
    .contact-us .pf-btn.pf-btn-primary {
        width: 100%;
    }
    .contact-us .ecs-row {
        padding:0;
    }
    .contact-us .grey-bg {
        margin-bottom: 10px;
    }
    .trade-enquiries .w-col.w-col-12 {
        text-align:unset;
    }
    #create-btn, .trade-enquiries .pf-btn.pf-btn-primary.contact-submit {
        min-width: 100%;
    }
    .trade-enquiries .create-account-label {
        width: 70.75%;
    }
    .international-shipping-row {
        padding:0;
    }
    .international-shipping .ecs-row-full-width.grey-section {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .international-shipping img {
        margin-top: 15px;
        width: 100%;
    }
    .find-us-row iframe {
        padding-top:60px;
    }
    #pf-product-list-page.pf-page-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    #pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty {
        margin-left: 0;
    }
    #pf-product-listing .flex-col {
        width: 49%!important;
    }
    #pf-product-listing .flex-col:nth-of-type(3n+1), #pf-product-listing .flex-col:nth-child(2n+2) {
        margin-left: 1% !important;
    }
    #pf-product-listing .flex-col:last-child, #pf-product-listing .flex-col:nth-child(3+3), #pf-product-listing .flex-col:nth-child(2n+1) {
        margin-right: 1% !important;
    }
    #pf-product-listing .flex-col:nth-of-type(2n+1) {
        margin-left: 0 !important;
    }
    #pf-product-listing .flex-col:last-child, #pf-product-listing .flex-col:nth-child(2+2) {
        margin-right: 0 !important;
    }
    h1.productListingMainHeading {
        margin-top: 20px;
    }
    #pf-product-details-page > .ecs-row:first-child > .ecs-column-2:last-child,
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:first-child {
        width: 100%;
    }
    .pf-product-details-heading.show-mobile {
        display:block;
    }
    .pf-recent-related-header {
        font-size: 20px;
        line-height: 28px;
    }
    #pf-product-details-page > .ecs-row:first-child > .ecs-column-2:first-child,
    #pf-product-details-page.product-wide > .ecs-row:first-child > .ecs-column-2:last-child {
        width: 100%;
        margin-right: 0;
    }
    #pf-product-details-page .breadcrumb {
        text-align: center;
        margin-bottom: 25px !important;
        margin-top: 10px;
    }
    .pf-product-details-heading.show-mobile {
        margin-top:0;
    }
    #pf-product-details-page .pf-slider-button.pf-slider-button-next {
        right: 0;
    }
    #pf-product-details-page .pf-slider-button.pf-slider-button-prev {
        left: 0;
        z-index: 9;
    }
    #pf-product-details-page {
        padding-left: 20px;
        padding-right: 20px;
    }
    th.cart-qty, th.cart-remove, th.cart-price, td.cart-qty, td.cart-remove, td.cart-price, .pf-checkout-cart-footer-row th.checkout-cart-footer-padding-remove, th.checkout-cart-footer-padding-total-tax, th.checkout-cart-footer-padding-price, th.checkout-cart-footer-padding-qty {
        display: none;
    }

    #checkout-cart .pf-mobile-qty-wrapper, #checkout-cart .cart-description .cart-price {
        display: block;
    }
    #checkout-cart .pf-mobile-qty-wrapper {
        margin-top:10px;
        margin-bottom:20px;
    }
    .checkout-cart-footer-row.pf-checkout-cart-footer-row .checkout-cart-footer-row-label {
        padding-right: 10px;
    }
    td.cart-total-combined, #totalcart {
        text-align: end;
    }
    .cart-first img {
        position: relative;
        margin:0;
        top: initial;
    }
    .cart-first {
        float: none;
    }
    td.cart-total-combined:not(p) {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    #cartModal .cart-buttons-popup a.pf-proceed-checkout-btn, #cartModal .cart-buttons-popup a.pf-request-check-stock, #cartModal .cart-buttons-popup a.pf-continue-shopping-cart, .request-check-stock-wrapper .pf-request-check-stock-btn, .pf-save-for-later.pull-left.pf-btn.pf-btn-sm.pf-btn-primary {
        width: 100% !important;
    }
    .request-check-stock-wrapper .pf-request-check-stock-btn {
        position:relative;
    }
    .request-check-stock-wrapper {
        padding: 0 10px 20px 10px;
        margin-top:0;
        margin-bottom:30px;
    }
    .request-check-stock-wrapper .ecs-row.text-align-center {
        padding:0;
    }
    #checkout-cart td.cart-description {
        padding-top: 5px!important;
    }
    th.cart-description {
        padding-left:0;
    }
    .price.cart-price .cart-label {
        font-size:18px;
        line-height:26px;
        font-weight:600;
    }
    #cartModal {
        padding: 10px;
    }
    #cartModal.pf-cart-modal .remodal-close {
        right: 18px;
    }
    #selectPaymentMethod h3, .check-h3.checkout-title {
        font-size: 24px;
    }
    #checkout .cart-first img, .quick-order .cart-first img {
        top: unset;
    }
    #billing, #delivery {
        font-size: 16px;
        line-height:22px;
    }
    #checkout .postcode-lookup-input, #checkout .postcode-lookup-btn {
        max-width: 100%;
        display:block;
        width:100%;
    }
    #billing-auto-wrapper .ecs-row, .delivery.order-totals .ecs-row, #checkout .ecs-row {
        padding: 0;
    }
    .delivery-options {
        text-align:start;
        margin-top:25px;
    }
    .delivery.order-totals, .delivery.order-totals .order-total-label {
        text-align: start;
    }
    .delivery.order-totals .ecs-row .ecs-column-2 {
        width: 47.25%;
        margin-right: 5.5%;
    }
    .delivery.order-totals .ecs-row  .ecs-column-2:last-child {
        text-align:end;
    }
    .pf-terms-wrapper p {
        text-align: start;
    }

    #consent-cbx, #terms-cbx {
        margin-right: 7px;
    }
    #makePaymentBtn {
        width:100%;
    }
    .pf-checkout-actions .ecs-row.pf-terms-wrapper {
        padding:15px!important;
    }
    .checkbox-field.w-checkbox.w-clearfix.signup-terms-cbx-wrapper {
        float:left;
    }
    .ecs-row.quick-order {
        padding: 0;
    }
    .style-container .style-item {
        flex: 0 100%;
        text-align: center;
    }
    #sidenav {
        background-color: #fff;
        color: #000;
    }
    #sidenav .heading.w-dropdown-link {
        font-size: 16px;
        line-height:22px;
        margin-bottom: 0;
    }
    #sidenav .pf-highlight-nav-wrapper {
        background-color:#eee9e7;
    }
    #sidenav .pf-highlight-nav-wrapper ul{
        background-color:#fff;
    }
    #sidenav a span.pf-highlight-nav {
        color:#000;
    }
    #sidenav span, #sidenav a, #sidenav a.heading {
        padding: 2px 20px;
        margin-bottom: 10px;
        text-transform:uppercase;
    }
    #sidenav .mm-spn--parent.mm-spn--open span {
        text-transform: initial;
    }
    #sidenav .dropdownlink.w-dropdown-link, #sidenav .dropdownlink.w-dropdown-link span{
        margin-bottom:0;
    }
    .mm-spn li:after {
        display: none !important;
    }
    #sidenav li:before {
        top:12px;
    }
    .signup-terms-cbx-wrapper #terms-cbx {
        margin-bottom: 37px;
    }
    #BtnAddPurchaserNote {
        margin-top: 0;
    }
    .pf-checkout-cart-table td:first-of-type,.pf-checkout-cart-table th:first-of-type {
        display: table-cell;
    }
    .cart-first.tablet-only, .pf-checkout-cart-table th.checkout-cart-footer-padding-price {
        display: none;
    }
    .pf-checkout-cart-table thead {
        color:#000;
    }
    .grey-section.section-under-slider h1 {
        font-size: 30px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .grey-section.section-under-slider h1 span {
        font-size: 24px;
        line-height: 28px;
    }
    .grey-section.section-under-slider h1 strong{
        margin-bottom: 10px;
        font-size: 31px;
        line-height: 44px;
    }
    .grey-section.section-under-slider h2 {
        font-weight:600;
    }
    .grey-section.section-under-slider h3 span, .grey-section.section-under-slider p span {
        color:#707070!important;
    }
    .grey-section.section-under-slider h1 span > br,
    .grey-section.section-under-slider h3 span > br {
        display: none;
    }
    .showroom .ecs-column-3 {
        width: 100%;
    }
    .showroom-item img {
        height: 100%;
    }
    .site-button.postcode-lookup-btn.pf-btn.pf-btn-md.pf-btn-primary {
        width:100%;
    }
    #pf-address-auto-wrapper {
        margin-bottom:20px;
    }
    #wf-form-Secure-Login-Form .pf-btn.pf-btn-primary {
        width: 100%;
        margin-bottom:5px;
    }
    .pf-reset-pass.pf-normal-link {
        float:left;
    }
    #favs-wrapper .pf-btn.pf-btn-sm.pf-btn-primary, .pf-create-wish-list, #info-tab-content .pf-btn.pf-btn-sm.pf-btn-primary, #cancel-details-btn, .pf-quick-order-wrapper .pf-proceed-checkout-btn {
        width: 100%;
    }
    #save-address-btn {
        width: 100%;
        padding: 8px 15px 8px 15px;
    }
    .pf-checkout-actions #terms-cbx {
        margin-bottom: -26px;
    }
    #sidenav {
        display:none;
    }
    #sidenav.mm-spn--light {
        display:block;
    }
    .pf-order-return-checkbox {
        height:14px;
        margin:0;
    }
    .pf-my-orders-table tr, .pf-my-returns-table tr {
        display:table-row;
    }
    .mobile-logo {
        display:block;
    }
    .no-mobile-logo {
        display:none;
    }
    .mm-spn.mm-spn--navbar.mm-spn--main:after, .mm-spn.mm-spn--navbar ul:before {
        display: none!important;
    }
    #sidenav a.heading span {
        text-transform:uppercase;
    }
    .ui-menu .ui-menu-item {
        font-size:13px;
    }
    .full-width-section.grey-section .showroom-mobile-img {
        display: block;
    }
    .showroom .ecs-row-full-width.homepage-row .ecs-column-3-2 {
        display:none;
    }
    .showroom .full-width-section.grey-section {
        flex-wrap: wrap;
        padding-bottom: 25px;
    }
    .finance h4 {
        margin-bottom:0;
    }
    .deliveries.ecs-main-container {
        padding-bottom:0;
    }
    .pf-related-viewed-section .pf-slide-item {
        border:none;
    }
    .pf-detail-view-add-cart-wrapper .pf-qty-btn {
        width:40px;
    }
    .cart-remove {
        display:none;
    }
    .cart-remove-mobile {
        display: block;
        margin-bottom: 20px;
    }
    .ecs-row-container.cart-buttons-popup {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .cart-buttons-popup .pf-proceed-checkout-btn, .cart-buttons-popup .pf-request-check-stock {
        text-align:start;
    }
    .cart-buttons-popup .pf-proceed-checkout-btn .fa-angle-right, .cart-buttons-popup .pf-request-check-stock .fa-angle-down {
        position: absolute;
        right: 15px;
        top: 18px;
    }
    .cart-buttons-popup .pf-continue-shopping-cart {
        text-align:end;
    }
    .cart-buttons-popup .pf-continue-shopping-cart .fa-angle-left {
        position: absolute;
        left: 15px;
        top: 18px;
    }
    .request-check-stock-wrapper {
        color:#000;
    }
    #checkout {
        padding-right:15px;
        padding-left:15px;
    }
    #selectPaymentMethod .center {
        margin-top:20px;
        text-align:start!important;
    }
    #checkout .ecs-row h3 {
        text-align: start;
    }
    .enquire-btn {
        width: 100%;
        height: 36px;
        background-color: #eee9e7;
    }
    .slider-main-banner-wrapper .slider {
        height: 400px;
        max-height:unset;
        min-height:unset;
    }
    .slider-main-banner-wrapper .slider .w-slider-nav {
        font-size:10px;
    }
    .slider-main-banner-wrapper .slider .w-slider-dot {
        border:none;
    }
    #pf-product-listing .flex-col:nth-child(2n+1) {
        margin-right: 0;
    }
    .header.shrink .flex-header-links {
        display: flex;
    }
    .shrink .brand {
        transform: none;
    }
    .bag-interaction {
        background: none;
    }
    .shrink .ecs-row.mobile-flex-col-logo.flex-header-links {
        display: none;
    }
}
@media screen and (max-width: 520px) {
    .slider-main-banner-wrapper .slider {
        height: 330px;
    }
    .paypal-cart {
        min-width: unset;
        float:unset;
    }
    .blog.section .ecs-row-full-width.blog-list {
        grid-template-columns: 1fr;
    }
    .style-section .section.title-wrapper {
        width: 70%;
    }
    #create-account-form .ecs-row {
        padding: 0;
    }
    .pf-popup-cart-loader {
        padding-left: 0;
        padding-right: 0;
    }
    .remodal-close:before {
        width:18px;
        height:18px;
    }
    #cartModal .remodal-close:before {
        font-size: 40px;
        top: -25px;
    }
    #cartModal.pf-cart-modal .remodal-close {
        right:0;
    }
    #BtnAddPurchaserNote {
        width:unset!important;
    }
    .remodal-wrapper {
        padding:5px;
    }
    table.cart-table tbody > tr > td, table.cart-table tbody > tr > th {
        padding: 3px;
    }
    #pf-gallery-image-products .flex-col {
        flex: 0 100%;
    }
    .cart-table tr td:nth-child(1) {
        width: 30%;
        padding: 1px;
    }

    .pf-checkout-cart-table .cart-description {
        width: 140px;
    }
    .pf-product-list-item .pf-enquire-product {
        min-width:unset;
        height:unset;
        min-height:40px;
    }
    #continueShoppingBtn {
        margin-bottom:10px;
    }
}
@media screen and (max-width: 420px) {
    .sidenav-btn-wrapper {
        margin-top: 5px;
    }
    .style-section .section.title-wrapper {
        width: 90%;
    }
    .price-match-form {
        padding: 50px 10px 24px 10px;
    }
    .header-link p {
        font-size:10px;
    }
    .flex-header-links {
        flex-wrap:nowrap;
    }
    .header-link {
        width:unset;
    }
    .pf-load-more-btn {
        width: 100%;
    }
    #cartModal {
        padding: 5px;
    }
    .ecs-row-container.cart-buttons-popup {
        padding-left: 5px;
        padding-right: 5px;
    }
    .pf-reset-pass {
        float:left;
    }
    #wf-form-Secure-Login-Form .pf-btn.pf-btn-sm.pf-btn-primary {
        margin-bottom:5px;
    }
    .section table tr td, .section table thead, .ecs-column-2 table tr td, .ecs-column-2 table thead, table tr td, table thead, table tr td, table thead {
        padding: 5px !important;
    }
    .trade-enquiries .ecs-row-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .trade-enquiries #create-account-form, .trade-enquiries form {
        padding: 50px 5px 0 5px;
    }
}
/* iPad Pro 12.9" Landscape - fix for cart padding */
@media only screen and (width: 1366px) and (max-device-width: 1024px) and (orientation: landscape) {
    .shrink #bag-opener.nav-link.mid {
        padding: 22px 0 25px 10px;
    }
    .mega-list.w-dropdown-list .ecs-row {
        display: flex !important;
        height:100%!important;
    }
    
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .nav-menu.w-nav-menu .mega-list.w-dropdown-list {
        min-height: 40vh !important;
    }
    .shrink #bag-opener.nav-link.mid {
        padding: 22px 0 25px 10px;
    }
}
#pf-product-listing .pf-filter-item input, #filtersOnly .pf-filter-dropdown-list .pf-filter-item input {
    height: unset;
}

#pf-product-listing .dropdown-toggle.pf-filter-item {
    background-color: #eee9e7;
    color: #000;
}
#pf-product-listing a#pf-clear-filters-btn:hover {
    color: #f5dae9 !important;
}
#pf-product-listing a#pf-clear-filters-btn {
    background-color: #000;
    color: #fff;
}
#pf-product-listing #filter .cat-selected {
    color: #707070 !important;
}
.section table tr td, .section table thead, .ecs-column-2 table tr td, .ecs-column-2 table thead, table tr td, table thead, table tr td, table thead {
    border: 1px solid #000;
    padding: 16px;
}
.pf-return-details {
    border-top: none;
    border-bottom: none;
}
.mbss-content h3 {
    margin-bottom: 10px;
}
#pf-product-details-page .pf-product-details-info-wrapper .pf-product-details-heading {
    width: 77%;
}
@media screen and (max-width: 768px) {
    .ecs-row-full-width.homepage-row.homepage-header {
        padding-bottom: 20px;
    }
}
#list-products-wrapper .flex-col.border-spaced {
    display: grid;
    grid-auto-rows: 1fr;
}
#list-products-wrapper .productwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#list-products-wrapper .pf-product-list-item > a {
    flex: 1;
    display: flex;
    flex-direction: column;
}
#list-products-wrapper .product-info-match-height {
    flex: 1;
    display: flex;
    flex-direction: column;
}
#list-products-wrapper .product-name.pf-product-name {
    flex: 1;
}
.nav-menu.top .ecs-column-2:last-child {
    left: 6%;
}
.nav-menu.top .nav-link.w-nav-link {
    padding: 5px 6px 5px;
}
@media screen and (max-width: 1200px) {
    .nav-menu.top .ecs-column-2:last-child {
        display: none;
    }
}
.style-section .title-wrapper h2.copyrighttext {
    border-bottom: none !important;
    font-size: 90%;
    padding-right: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    float: none;
    white-space: nowrap;
    color: rgb(169,169,169,70%);
}
@media screen and (max-width: 449px) {
    .pf-modal-save-cart .container-save-cart span.pull-left {
        float: none;
    }

    .pf-modal-save-cart .container-save-cart .container-btns-save-cart {
        margin-top: 30px;
    }

    .pf-modal-save-cart .container-save-cart .pf-save-cart-btn {
        float: none;
        min-width: 208px;
        left: unset;
        top: 10px;
    }

    .pf-modal-save-cart .container-save-cart input.pf-cart-reference {
        float: none;
        min-width: 207px;
    }
}
#create-account-form #pf-address-manual-wrapper p:first-of-type {
    display: none;
}
.blog-details .post img {
    height: auto;
}
.wish-list-parent .fa-heart {
    font-size: 20px;
}
.pf-product-list-item .wish-list-parent {
    margin-top:15px;
}
.pf-product-list-item .wish-list-parent .add-to-wishlist-btn span {
    display:none;
}
.pf-product-list-item .product-info-match-height {
    padding-top: 0;
}
.style-container .style-item:last-of-type {
    flex: 0 21%;
}
.international-shipping p > strong, .showroom p > strong, .legal-template p > strong,
.trade-enquiries p > strong, .find-us p > strong, .deliveries-row p > strong {
    font-family: 'Gill Sans Bold';
    font-weight: 300;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .news.section.full-width-section .blog-feed-row .w-col-3 {
        width: 34% !important;
    }
    .news.section.full-width-section .blog-feed-row .w-col-3:nth-child(5) {
    display: block !important;
}
}
.news.full-width-section .blog-feed-row .w-col-3 {
    width: 20% !important;
}
#pf-product-details-page .product-detail-description p > strong {
    font-weight: bold;
}
#create-account-form .h-captcha.pull-left {
    float: right;
}
.w-col.captchamessage {
    padding-left: 27px;
}
.showroom h4 {
    font-size: 20px !important;
    line-height: 24px;
}
.trade-enquiries .ecs-row div.w-col div br {
    display: none;
}
.pf-custom-stock-message {
    color: #ec99c5;
    font-size: 18px;
}
@media screen and (max-width: 768px) {
  .pf-detail-view-add-cart-wrapper .pf-qty-btn {
    width: 35px;
  }

  .pf-detail-view-add-cart-wrapper .pf-qty-btn, .pf-detail-view-add-cart-wrapper .pf-qty-select {
    height: 35px;
  }
  #pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty {
    height: 35px;
    line-height: 25px;
    font-size: 16px;
    min-width: 160px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 9px;
  }
}
@media screen and (max-width: 328px) {
  #pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn-with-qty {
    margin-left: 0;
    display:block;
    width:160px;
  }
}